import React, { useEffect, useState } from 'react';
import { trpc } from '../utils/trpc-client';
import { LabelModel } from 'shared/src/metadata-types';
import { Combobox } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faX, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

export type ModelTagSelectorProps = {
  tagIds?: number[];
  onTagChange: (tagIds: number[]) => void;
  showCreate?: boolean;
};

export default function ModelTagSelector({
  tagIds,
  onTagChange,
  showCreate
}: ModelTagSelectorProps) {
  const { data, isLoading, refetch } = trpc.labels.useQuery();
  const { mutateAsync, isLoading: createPending } = trpc.addLabel.useMutation();
  const [tagQuery, setTagQuery] = useState('');
  const [selectedTags, setSelectedTags] = useState([] as LabelModel[]);

  useEffect(() => {
    if (tagIds && tagIds.length > 0 && data) {
      setSelectedTags(data.filter(tag => tagIds.includes(tag.id)));
    }
  }, [data, tagIds, onTagChange]);

  if (isLoading || createPending) {
    return <div>Loading...</div>;
  }

  const filteredData =
    tagQuery === ''
      ? data
      : data?.filter(tag => {
          return tag.name.toLowerCase().includes(tagQuery.toLowerCase());
        });

  const handleTagChange = (newSelectedTags: LabelModel[]) => {
    setSelectedTags(newSelectedTags);
    onTagChange(newSelectedTags.map(tag => tag.id));
  };

  async function createTag() {
    if (!tagQuery) {
      return;
    }

    const tag = await mutateAsync({ name: tagQuery });
    await refetch();
    setTagQuery('');

    if (!selectedTags.find(selectedTag => selectedTag.id == tag.id)) {
      selectedTags.push(tag);
      handleTagChange(selectedTags);
    }
  }

  const removeTag = (tagToRemove: LabelModel) => {
    const newSelectedTags = selectedTags.filter(tag => tag.id !== tagToRemove.id);
    handleTagChange(newSelectedTags);
  };

  return (
    <div>
      {selectedTags.sort(tagNameCompare).map(tag => (
        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-700/10">
          {tag.name}
          <div className="pl-2 text-black hover:cursor-pointer">
            <FontAwesomeIcon icon={faX} onClick={() => removeTag(tag)} />
          </div>
        </span>
      ))}
      <Combobox multiple as="div" value={selectedTags} onChange={handleTagChange}>
        <div className="relative mt-2 w-fit">
          <Combobox.Input
            autoFocus={true}
            className="block w-fit rounded-md bg-white py-1.5 pl-3 pr-12 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
            displayValue={() => tagQuery}
            onChange={event => setTagQuery(event.target.value)}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <FontAwesomeIcon icon={faChevronDown} />
          </Combobox.Button>
          <Combobox.Options className="absolute z-10 mt-1 max-h-52 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {filteredData?.sort(tagNameCompare).map(tag => (
              <Combobox.Option
                key={tag.id}
                value={tag}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 hover:cursor-pointer hover:bg-indigo-100 focus:outline-none aria-selected:bg-indigo-300">
                <span className="block truncate group-aria-selected:font-semibold">{tag.name}</span>
              </Combobox.Option>
            ))}
            {showCreate && (
              <>
                <hr />
                <button
                  disabled={!tagQuery}
                  onClick={createTag}
                  className="group relative w-full cursor-default select-none py-2 pl-3 pr-9 text-gray-900 hover:cursor-pointer hover:bg-indigo-100 focus:text-white focus:outline-none disabled:text-gray-500">
                  <FontAwesomeIcon icon={faPlusCircle} className="pr-2" />
                  {tagQuery ? `Create Tag: ${tagQuery}` : 'Create Tag'}
                </button>
              </>
            )}
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  );
}

function tagNameCompare(a: { name: string }, b: { name: string }): number {
  return a.name.localeCompare(b.name);
}
