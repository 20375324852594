import React from 'react';
import { trpc } from '../utils/trpc-client';
import { Badge, Cell, Header, Table } from '../components/table';
import { formatNumber } from '../utils/formatting-utils';
import { formatDate } from 'shared/src/date-utils';
import { PublishedModelWithLicenseDetails, TagDetail } from 'shared/src/metadata-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faChevronDown, faFilter } from '@fortawesome/free-solid-svg-icons';
import { RefetchOptions } from '@tanstack/react-query';
import { EditMetadataModal } from '../components/edit-metadata-modal';
import ConfirmDialog from '../components/confirm-dialog';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ModelTagSelector from '../components/model-tag-selector';

type ModelSortFields = Pick<
  PublishedModelWithLicenseDetails,
  'modelId' | 'numParameters' | 'modelType' | 'license' | 'firstPublished' | 'contextWindowSize'
>;

export function PublishedSection() {
  const { data, isLoading, refetch } = trpc.modelsLicense.useQuery();
  const [sortColumn, setSortColumn] = useState<keyof ModelSortFields>('modelId');
  const [tagFilterOpen, setTagFilterOpen] = useState(false);
  const [filterTagIds, setFilterTagIds] = useState<number[]>([]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  function SortableHeader({ label, sortKey }: { label: string; sortKey: keyof ModelSortFields }) {
    return (
      <th
        scope="col"
        className="px-3 py-5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer"
        onClick={() => setSortColumn(sortKey)}>
        {label} {sortColumn === sortKey && <FontAwesomeIcon icon={faChevronDown} />}
      </th>
    );
  }

  function TagsHeader({ label }: { label: string }) {
    return (
      <th
        scope="col"
        onClick={() => setTagFilterOpen(true)}
        className="px-3 py-5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer">
        <span className="p-1">{label}</span>
        <FontAwesomeIcon
          icon={faFilter}
          className={`rounded p-1 text-gray-700 ${filterTagIds.length > 0 && 'bg-blue-300'}`}
        />
      </th>
    );
  }

  const modelMatchesTagFilters = (model: PublishedModelWithLicenseDetails) =>
    filterTagIds.every(filterTagId => model.tags.some(tag => tag.id === filterTagId));

  const models = data || [];
  const filteredModels = filterTagIds.length > 0 ? models.filter(modelMatchesTagFilters) : models;

  return (
    <Table>
      <thead className="bg-gray-50">
        <tr>
          <SortableHeader label="Model Id" sortKey="modelId" />
          <SortableHeader label="Num Params" sortKey="numParameters" />
          <SortableHeader label="Model Type" sortKey="modelType" />
          <SortableHeader label="License" sortKey="license" />
          <SortableHeader label="Publish Date" sortKey="firstPublished" />
          <SortableHeader label="Context Size" sortKey="contextWindowSize" />
          <Header label="Formats" />
          <TagsHeader label="Tags" />
          <Header label="" />
        </tr>
        <TagsFilterModal
          open={tagFilterOpen}
          setOpen={setTagFilterOpen}
          onFilterChange={setFilterTagIds}
        />
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {filteredModels
          .sort((a, b) => modelSortCompare(a[sortColumn], b[sortColumn]))
          .map(model => (
            <tr key={model.modelId}>
              <Cell>
                <ModelModal model={model} refetch={refetch} />
              </Cell>
              <Cell>{formatNumber(model.numParameters)}</Cell>
              <Cell>{model.modelType}</Cell>
              <Cell>{model.license}</Cell>
              <Cell>{formatDate(model.firstPublished)}</Cell>
              <Cell>{model.contextWindowSize}</Cell>
              <Cell>
                <div className="flex gap-1">
                  {model.quantizedFiles
                    .sort((a, b) => a.quantMethod.localeCompare(b.quantMethod))
                    .map((qfile: { quantMethod: string }) => (
                      <Badge
                        key={model.modelId + qfile.quantMethod}
                        label={qfile.quantMethod}
                        color="blue"
                      />
                    ))}
                </div>
              </Cell>
              <Cell>
                <div className="flex gap-1">
                  <TagsColumn modelId={model.modelId} tags={model.tags} />
                </div>
              </Cell>
              <Cell>
                <DeleteButton model={model} refetch={refetch} />
              </Cell>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

function modelSortCompare(a: string | number, b: string | number): number {
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }
  return a === b ? 0 : a > b ? 1 : -1;
}

type ModelModalTypes = {
  model: PublishedModelWithLicenseDetails;
  refetch: (options?: RefetchOptions) => unknown;
};

function ModelModal({ model, refetch }: ModelModalTypes) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div
        onClick={() => setOpen(true)}
        className="text-indigo-600 hover:cursor-pointer hover:text-indigo-900">
        {model.modelId}
      </div>
      <EditMetadataModal model={model} open={open} setOpen={setOpen} refetch={refetch} />
    </>
  );
}

type DeleteButtonProps = {
  model: PublishedModelWithLicenseDetails;
  refetch: (options?: RefetchOptions) => unknown;
};

function DeleteButton({ model, refetch }: DeleteButtonProps) {
  const [open, setOpen] = useState(false);
  const { mutateAsync } = trpc.unpublishModel.useMutation();

  async function onConfirm() {
    await mutateAsync({ id: model.modelId });
    refetch();
    setOpen(false);
  }

  return (
    <>
      <FontAwesomeIcon
        icon={faTrashCan}
        className="text-gray-400 hover:cursor-pointer hover:text-red-600"
        onClick={() => setOpen(true)}
      />
      <ConfirmDialog
        title="Unpublish Model"
        open={open}
        setOpen={setOpen}
        onConfirm={onConfirm}
        message={
          <p className="text-sm text-gray-500">
            Are you sure you want to unpublish the{' '}
            <span className="font-bold">{model.modelId}</span> model? Any consumers of the Kurator
            API will no longer be able to download this model
          </p>
        }
      />
    </>
  );
}

type TagsColumnProps = {
  modelId: string;
  tags: TagDetail[];
};

function TagsColumn({ modelId, tags }: TagsColumnProps) {
  return (
    tags.length > 0 && (
      <>
        <Badge key={modelId + tags[0].id} label={tags[0].name} color="green" />
        {tags.length > 1 && (
          <Badge key={`${modelId}-plus`} label={`+${tags.length - 1}`} color="green" />
        )}
      </>
    )
  );
}

type TagsFilterModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onFilterChange: (tagIds: number[]) => void;
};

function TagsFilterModal({ open, setOpen, onFilterChange }: TagsFilterModalProps) {
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>([]);

  const onTagIdsChange = (tagIds: number[]) => {
    setSelectedTagIds(tagIds);
    onFilterChange(tagIds);
  };

  const clearAll = () => {
    setSelectedTagIds([]);
    onFilterChange([]);
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          as={Fragment}>
          <div className="fixed inset-0 bg-gray-500/25 transition-opacity" />
        </Transition.Child>
        <div className="fixed right-5 top-5 z-10 max-w-fit overflow-y-visible p-4 sm:p-6 md:p-20">
          <Transition.Child
            enter="transition duration-300 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-200 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            as={Fragment}>
            <Dialog.Panel className="mx-auto w-fit transform rounded-xl bg-white p-2 shadow-2xl ring-1 ring-black/5 transition-all">
              <Dialog.Title>Tag Filters</Dialog.Title>
              <ModelTagSelector tagIds={selectedTagIds} onTagChange={onTagIdsChange} />
              <button
                type="button"
                onClick={clearAll}
                autoFocus={false}
                className="mt-2 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                Clear All
              </button>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
